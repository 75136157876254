import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.querySelector('form').addEventListener('submit', function(event) {
      let tattooerNameField = document.getElementById('tattooer-select');
      if (!tattooerNameField.value) {
        event.preventDefault(); // Prevent form submission

        // this changes the scrolling behavior to "smooth"
        window.scrollTo({ top: 0, behavior: 'smooth' });
        alert('Please select a Tattooer before submitting the form.');
      }
    });
  }
}
