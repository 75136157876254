import { Controller } from "@hotwired/stimulus"
import { Unlayer2be } from 'unlayer2be'
// import data from '../../../db/data/email_templates/base-one-column.json';

export default class extends Controller {
  static targets = [ "form", "body", "template" ]

  static values = {
    template: Object,
    autoSave: Boolean,
    emailTemplateId: Number
  }

  connect() {
    console.log('Unlayer controller connected');
      unlayer.init({
        id: 'editor-container',
        projectId: 246804,
        displayMode: 'email'
      })


      // const design = Unlayer2be.design(data);
      // console.log(design);
      // unlayer.loadDesign(alternate_base);
      if (this.templateValue) {
        unlayer.loadDesign(this.templateValue);
        unlayer.exportHtml((data) => {
          console.log('design:loaded');
          // console.log(data);
          this.bodyTarget.value = data.html;
        });
      }

    // set values on connect
      // unlayer.exportHtml((data) => {
      //   console.log('design:updated');
      //   // console.log(data);
      //   this.bodyTarget.value = data.html;
      //   this.templateTarget.value = JSON.stringify(data.design);
      // });
        unlayer.exportHtml((data) => {
          console.log('design:loaded');
          // console.log(data);
          this.bodyTarget.value = data.html;
        });

      unlayer.addEventListener('design:updated', (updates) => {
        unlayer.exportHtml((data) => {
          console.log('design:updated');
          // console.log(data);
          this.bodyTarget.value = data.html;
          this.templateTarget.value = JSON.stringify(data.design);
        });
      });

      // unlayer.addEventListener('image:uploaded', (updates) => {
      //   console.log(updates)
      //   unlayer.exportHtml((data) => {
      //     console.log('image:uploaded');
      //     console.log(data);
      //     this.bodyTarget.value = data.html;
      //     this.templateTarget.value = JSON.stringify(data.design);
      //   });
      // })
  }
}
